<template>
    <div class="acDetail">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>账户管理</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="goback1">WEB账号管理</el-breadcrumb-item>
            <el-breadcrumb-item>{{userName}}</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 展示详情的 -->
        <div class="acdMain">
            <div class="acInfo" v-if="pageData">
                <el-descriptions direction="horizontal" :column="1" border>
                  <el-descriptions-item label="用户类型" v-if="pageData.type!=null">{{pageData.type}}</el-descriptions-item>
                  <el-descriptions-item label="用户类型" v-if="pageData.type==null">--</el-descriptions-item>
  
                  <el-descriptions-item label="机构信息" v-if="pageData.orgName!=null">{{pageData.orgName}}</el-descriptions-item>
                  <el-descriptions-item label="机构信息" v-if="pageData.orgName==null">--</el-descriptions-item>
  
                  <el-descriptions-item label="地区" v-if="(pageData.provinceName==null) && (pageData.cityName==null)">--</el-descriptions-item>
                  <el-descriptions-item label="地区" v-if="(pageData.provinceName!=null) && (pageData.cityName!=null)">{{ pageData.provinceName+pageData.cityName}}</el-descriptions-item>
  
                  <el-descriptions-item label="用户名" v-if="pageData.userName!=null">{{pageData.userName}}</el-descriptions-item>
                  <el-descriptions-item label="用户名" v-if="pageData.userName==null">--</el-descriptions-item>
  
                  <el-descriptions-item label="手机号" v-if="pageData.mobile!=null">{{pageData.mobile}}</el-descriptions-item>
                  <el-descriptions-item label="手机号" v-if="pageData.mobile==null">--</el-descriptions-item>
  
                  <el-descriptions-item label="出生日期" v-if="pageData.birthday!=null">{{pageData.birthday}}</el-descriptions-item>
                  <el-descriptions-item label="出生日期" v-if="pageData.birthday==null">--</el-descriptions-item>
  
                  <el-descriptions-item label="性别" v-if="(pageData.gender!=null)&&(pageData.gender==1)">男</el-descriptions-item>
                  <el-descriptions-item label="性别" v-if="(pageData.gender!=null)&&(pageData.gender==0)">女</el-descriptions-item>
                   <el-descriptions-item label="性别" v-if="pageData.gender==null">--</el-descriptions-item>
              </el-descriptions>
                
              <div class="event">
                  <div class="delet" @click="deletAcc">删除</div>
                  <div class="delet" style="background-color: #00CCCC;margin-left:24px" @click="editAcc">编辑</div>
              </div>
            </div>
        </div>
        <!-- 编辑   :rules="editeRule"-->
          <el-dialog :visible.sync="edite">
                <el-form :model="ediForm" ref="editForm" >
                    <el-form-item label="用户类型：" :label-width="formLabelWidth" prop="type">
                        <el-input disabled v-model="ediForm.type" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="机构信息：" :label-width="formLabelWidth" prop="orgName">
                        <el-input disabled v-model="ediForm.orgName" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="地区：" :label-width="formLabelWidth">
                        <el-cascader
                          style="width:100%"
                          v-model="value"
                          :options="district"
                          :props="{ expandTrigger: 'hover' }"
                          @change="selectCity"
                          >
                       </el-cascader>
                    </el-form-item>
                    <el-form-item label="用户名：" :label-width="formLabelWidth" prop="userName">
                        <el-input disabled v-model="ediForm.userName" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号：" :label-width="formLabelWidth" prop="mobile">
                        <el-input v-model="ediForm.mobile" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="出生日期：" :label-width="formLabelWidth">
                        <el-date-picker
                          style="width:100%"
                          v-model="newBirth"
                          type="date"
                          @change="selectDate"
                          value-format="yyyy-MM-dd"
                          placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="性别：" :label-width="formLabelWidth">
                         <el-select v-model="newGender" placeholder="请选择" @change="selectGender" style="width:100%">
                          <el-option
                              v-for="item in genderList"
                              :key="item.value"
                              :label="item.value"
                              :value="item.label">
                              </el-option>
                          </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="ediCancel">取消</el-button>
                    <el-button type="primary" @click="ediSure">确定</el-button>
                </div>
          </el-dialog>
  
    </div>
  </template>
  
  <script>
  import  {viewUser,viewWXUser,updateUser} from '@/api/accountController.js'
  import  {district} from '../../utils/cityList.js'
  export default {
      beforeRouteLeave(to,from,next){
          if((to.name === 'WxUser') || (to.name === 'WebUser')){
              to.meta.keepAlive = true;
          }
          next();
      },
      data(){
          return{
              district,//省份json数据
              type:null,//机构类型
              edite:false,
              pageId:null,//用户id
              //账户详情数据：
              pageData:null,
              userName:null,
              //编辑状态数据
              ediForm:{
                  type:null,
                  orgName:null,
                  userName:null,
                  mobile:null,
              },
              //编辑需要单独提交的
              cityCode:null,
              proCode:null,
              newBirth:null,
              newGender:null,
              value:null,//所选地区
              //label-width
              formLabelWidth:'90px',
              //性别下拉列表
              genderList:[{value:'男',label:'1'},{value:'女',label:'0'}]
          }
      },
  
      created(){
        if(this.$route.query.id){
          this.pageId = this.$route.query.id;
          this.type = this.$route.query.type;
          this.getUserInfo(this.pageId,this.type);
        }
      },
      methods:{
        //面包屑返回
        goback1(){
            this.$router.go(-1);
        },
      getUserInfo(id,type){
          if(type == 'WEB'){
              viewUser({
                  id:id
              }).then(res=>{
                  if(res.data.status == 200){
                      this.pageData = res.data.data;
                      this.userName = res.data.data.userName;
                  }else{
                      this.$message.error(res.data.message);
                  }
              }).catch(res=>{
                  this.$message.error(res);
              })
          }else{
              viewWXUser({
                  id:id
              }).then(res=>{
                  if(res.data.status == 200){
                      this.pageData = res.data.data;
                      this.userName = res.data.data.userName;
                  }else{
                      this.$message.error(res.data.message);
                  }
              }).catch(res=>{
                  this.$message.error(res);
              })
          }
          
      },
    
      //删除该账户
       deletAcc(){
             this.$message.error("暂不支持！");
          },
          //编辑该账户
      editAcc(){
              this.edite = true;
              //信息回显
              this.ediForm.type = this.pageData.type;
              this.ediForm.orgName = this.pageData.orgName;
              this.value = [this.pageData.provinceCode,this.pageData.cityCode];
              this.ediForm.userName = this.pageData.userName;
              this.ediForm.mobile = this.pageData.mobile;
              this.newBirth = this.pageData.birthday;
              this.newGender = (this.pageData.gender==1) ? '男' : '女';
          },
      //取消编辑
      ediCancel(){
          this.edite = false;
          this.clear();//clear
      },
      //确认编辑   
      ediSure(){
          var temCityCode = (this.cityCode!=null) ? this.cityCode : this.value[1];
          var temProCode = (this.proCode!=null) ? this.proCode : this.value[0];
          var temGender = null;
          if((this.newGender!=1)&&(this.newGender!=0)){
              if(this.newGender=='男'){
                  temGender = '1';
              }else{
                  temGender = '0'
              }
          }else{
              temGender = this.newGender ;
          }
          updateUser({
              birthday:this.newBirth,
              gender:temGender,
              id:this.pageId,
              provinceCode:temProCode,
              cityCode:temCityCode,
              userMobile:this.ediForm.mobile
          }).then(res=>{
              if(res.data.status==200){
                  this.$message({
                    message: '更新成功！',
                    type: 'success'
                  });
                  this.getUserInfo(this.pageId);//update
                  this.edite = false;//close
                  this.clear();//clear
  
              }else{
                  this.$message.error(res.data.message);
              }
          }).catch(res=>{
               this.$message.error(res);
            })
      },
      //选择地区
      selectCity(value){
          this.cityCode = value[1];
          this.proCode = value[0];
      },
      //选择日期
      selectDate(value){
          this.newBirth = value;
      },
      //选择性别
      selectGender(value){
          this.newGender = value;
      },
      //清空编辑表单
      clear(){
        this.cityCode = null;
        this.proCode = null;
        this.newBirth = null;
        this.newGender =null;
        this.ediForm.mobile = null;
      }
  }
  
  }
  </script>
  <style lang="stylus">
  //component
  .el-breadcrumb__item:last-child .el-breadcrumb__inner{
      font-weight: bold !important;
    }
  
  //normal
  .acDetail
      overflow hidden
      .acdMain
          overflow hidden
          margin-top: 16px
          padding: 20px
          background-color: #FFFFFF
          .acInfo
              width: 80%
              margin: 26px auto 0 auto
              .acInfomation
                  display: flex
                  font-size: 18px 
                  font-family: Source Han Sans CN
                  margin-bottom: 80px
                  .acSpanL
                      margin-right: 150px 
                      display: block
                      width: 160px
                      color:#AEAEAE
                  .acSpanR
                      color:#353535
              .event
                  display: flex
                  margin-top: 80px
                  .delet
                      width: 100px
                      height 40px
                      line-height: 40px
                      font-family: Source Han Sans CN
                      font-size: 18px
                      color: #fff
                      text-align: center
                      background-color: #FC5555
                      cursor pointer
              
  
  
  
  </style>